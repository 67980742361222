<template>
	<router-view
		:tiers="tiers"
	></router-view>
</template>

<script>
export default {
	name: 'TiersMonte',
	props: ['tiers']
}
</script>
